import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const CookiePage = () => (
  <Layout>
    <Seo title="Cookie policy" />
    <section className="page-header">
      <h1>Cookie policy</h1>
    </section>    

    <section className="container flex justify-center items-stretch">
      <div>
        <p>Op onze website (www.optiektomdebruyne.be) maken wij gebruik van cookies. Cookies zijn tekstbestanden die op uw computer of mobiel apparaat geplaatst worden en door uw internetbrowser opgeslagen worden. Cookies kunnen niet gebruikt worden om een persoon te identificeren, een cookie kan slechts een machine identificeren. Wij gebruiken technische cookies om onze websites (www.optiektomdebruyne.be) te optimaliseren en u meer comfort te bieden zoals het automatisch invullen van formulieren bijvoorbeeld.</p>
        <p>Verder zouden we de cookies kunnen gebruiken om te analyseren welke pagina's u hoe vaak bezoekt en om marketingonderzoek te doen. Hierbij maakt men gebruik van de online-tracking diensten van Google Analytics, een dienst voor de analyse van websites van Google Inc. Alle gegevens die we door middel van deze analyses verzamelen zijn geen persoonsgegevens en zijn dan ook volledig anoniem.</p>
        <p>U kunt uw internetbrowser instellen zodat cookies niet worden geaccepteerd, u een waarschuwing ontvangt wanneer een cookie geïnstalleerd wordt of dat de cookies nadien van uw harde schijf worden verwijderd. Dit kan u doen via de instellingen van uw browser (help-functie). Hou er rekening mee dat de kans bestaat dat er hierdoor bepaalde grafische elementen niet correct worden getoond of dat bepaalde applicaties niet volledig werken.</p>
        <p>Door gebruik te maken van onze website, gaat u akkoord met ons gebruik van cookies.</p>
        <ul className="list-disc list-outside ml-4">
          <li className="my-3"><strong>_ga</strong><br/>
          Doel: Google Analytics: bijhouden van statistieken. Bijhouden van hoe de gebruiker de website bezoekt, met het oog op verbetering diensten en online portalen. Privacy policy van Google.<br/>
          Bewaartijd: 2 jaar.</li>
          <li className="my-3"><strong>_gid</strong><br/>
          Doel: Google Analytics: bijhouden van statistieken. Bijhouden van hoe de gebruiker de website bezoekt, met het oog op verbetering van onze diensten en online portalen. Privacy policy van Google.<br/>
          Bewaartijd: 24 uur.</li>
          <li className="my-3"><strong>_gat</strong><br/>
          Doel: Google Analytics: bijhouden van statistieken. Bijhouden van hoe de gebruiker de website bezoekt, met het oog op verbetering van onze diensten en online portalen. Privacy policy van Google.<br/>
          Bewaartijd: 1 minuut.</li>
          <li className="my-3"><strong>catAccCookies</strong><br/>
          Doel: Bijhouden van cookie keuze van de gebruiker op de website. Als deze cookie niet aanwezig is of vervallen is, wordt de cookie melding aan de gebruiker getoond.<br/>
          Bewaartijd: 30 dagen.</li>
        </ul>
      </div>
    </section>
</Layout>
)

export default CookiePage
